<template>
  <div class="bank-id-fail">
    <Container>
      <Card padding="large">
        <b-row>
          <b-col cols="12" sm="auto" class="text-center">
            <CloseSVG class="icon h1 text-error" />
          </b-col>
          <b-col>
            <Margins>
              <h1 class="h3 text-normal">
                {{ $t('SOMETHING_WRONG') }}
              </h1>

              <p>
                {{ $t('CREDIT_CARD.PAYMENT_FAIL.CONTACT_PROVIDER') }}
              </p>

              <Separator size="large" />
              <b-row align-h="center" class="margins__double">
                <b-col cols="auto">
                  <Button
                    @click="
                      $router.push({ name: ROUTES.CREDIT_CARD_PAYMENT.name })
                    "
                    :loading="isLoading"
                  >
                    {{ $t('CREDIT_CARD.PAYMENT_FAIL.TRY_AGAIN') }}
                  </Button>
                </b-col>
              </b-row>
            </Margins>
          </b-col>
        </b-row>
      </Card>
    </Container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Button, Card, Container, Margins, Separator } from '@/components';
import CloseSVG from '@/assets/images/times-circle-regular.svg';
import { constants } from '@/mixins';

export default {
  name: 'Fail',
  mixins: [constants],
  components: {
    Container,
    Card,
    Margins,
    Button,
    Separator,
    CloseSVG,
  },
  computed: {
    ...mapState(['isLoading']),
  },
};
</script>
